import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider, { dividerClasses } from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { Box } from "@mui/system";
import UserCard from "./Components/UserCard";
import FilterIcon from "./Icons/filterIcon.svg";

//   import SuspendAdminModal from "./SuspendAdminModal";
import { KeyboardArrowDown } from "@mui/icons-material";
import { alpha, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { Grid } from '@mui/material';
import DirectoryIcon from "./Icons/DirectoryIcon.svg";
import User1 from "./Icons/user1.png";
import User2 from "./Icons/user2.png";
import User3 from "./Icons/user3.png";
import User4 from "./Icons/user4.png";

import User5 from "./Icons/user5.png";
import User6 from "./Icons/user6.png";
import User7 from "./Icons/user7.png";
import User8 from "./Icons/user8.png";




const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '16px',
    borderColor: '#D4D4D4',
    border: '1px solid #D4D4D4',
    backgroundColor: "#F5F6FA",
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: '80%',

    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'black',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '40ch',
        },
    },
}));

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));

export default function RecipeReviewCard() {
    const [expanded, setExpanded] = React.useState(false);
    const data = [{
        name: 'Jason Price',
        designation: 'CEO',
        email: 'janick_parisian@rmc.med.sa',
        image: User1
    },
    {
        name: 'Jukkoe Sisao',
        designation: 'Medical Director',
        email: 'sibyl_kozey@rmc.med.sa',
        image: User2
    },
    {
        name: 'Harriet King',
        designation: 'Internal Medicine Head',
        email: 'nadia_block@rmc.med.sa',
        image: User3
    },
    {
        name: 'Lenora Benson',
        designation: 'Nursing Director',
        email: 'feil.wallace@rmc.med.sa',
        image: User4
    },
    {
        name: 'Noha Alawdy',
        designation: 'Internal Medicine Resident',
        email: 'Noha298@rmc.med.sa',
        image: User5
    },
    {
        name: 'Ibrahim Al Jaber',
        designation: 'Internal Medicine Resident',
        email: 'ibrahim_09@rmc.med.sa',
        image: User6
    },
    {
        name: 'Sayed Alasy',
        designation: 'Internal Medicine Resident',
        email: 'sayed-Alasy98@rmc.med.sa',
        image: User7
    },
    {
        name: 'Gamal Ayed',
        designation: 'Senior Internal Medicine Resident',
        email: 'GamalAA@rmc.med.sa',
        image: User8
    },
    ]

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (

        <Card style={{ width: '1140px' }} >
            <Grid container spacing={2}>
                <Grid item sm={4} >
                    <div >
                        <ListItem>
                            <ListItemAvatar>
                                <img src={DirectoryIcon} style={{ height: '50px', width: '50px' }}></img>
                            </ListItemAvatar>
                            <ListItemText primary={
                                <Typography style={{
                                    color: '#202224',
                                    fontWeight: 700,
                                    fontSize: '16px'
                                }}>
                                    Directory
                                </Typography>
                            } secondary={
                                <Typography style={{
                                    color: '#A7A7A7',
                                    fontWeight: 500,
                                    fontSize: '12px'
                                }}>
                                    10,000
                                </Typography>
                            } />
                        </ListItem>
                    </div>
                </Grid>
                <Grid item sm={5}>
                    <div style={{ padding: '10px' }}>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon style={{ color: '#000000' }} />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search Directory"
                                style={{
                                    width: '300px',
                                    height: '50px'
                                }}
                                inputProps={{ 'aria-label': 'search', color: '#000000' }}
                            />
                        </Search>
                    </div>
                </Grid>
                <Grid item sm={3}>
                    <div style={{ padding: '10px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 3,
                                bgcolor: '#F5F6FA',
                                color: 'text.secondary',
                                '& svg': {
                                    m: 0,
                                },
                                [`& .${dividerClasses.root}`]: {
                                    mx: 0.5,
                                },
                            }}
                        >
                            <div style={{ padding: '10px' }}>
                                <img src={FilterIcon}></img>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div style={{ padding: '10px' }}>
                                <Typography>
                                    Filter By
                                </Typography>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <span
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "Center",
                                    alignItems: "Center",
                                    // marginLeft: "5px"
                                }}
                            >
                                <Typography style={{ padding: '10px' }}>
                                    Doctors
                                </Typography>
                                <KeyboardArrowDown />
                            </span>

                        </Box>
                    </div>
                </Grid>
            </Grid>
            <CardContent>
                <Grid container spacing={2} style={{ height: '300px', overflow: 'auto', overflowX: 'hidden' }}>
                    {
                        data.map((item, index) => (
                            <Grid item sm={3}>
                                <UserCard data={item} />
                            </Grid>
                        ))
                    }
                </Grid>
            </CardContent>
        </Card>

    );
}
