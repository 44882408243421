import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import "react-circular-progressbar/dist/styles.css";

import userIcon from "../../../assets/Dashboard/userIcon.svg";
import userIconYellow from "../Icons/userIconYellow.png"
import userIconPink from "../Icons/userIconPink.png"

import userIconGreen from "../Icons/userIconGreen.png"


const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function BasicCard({ data }) {
  return (
    <div style={{ width: '1140px' }}>
      <Grid container spacing={3}>
        <Grid item sm={3}>
          <Card
            sx={{
              width: "270px",
              height: "112px",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: 0,
            }}
          >
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography sx={{ fontSize: "16px", fontWeight: 400, color: "#202224" }}>
                    Number of Employees
                  </Typography>
                  <Typography
                    sx={{ fontSize: "28px", fontWeight: 700, marginTop: '10px' }}
                    color="#202224"
                  >
                    12,000
                  </Typography>
                </div>
                <img src={userIconGreen} style={{ height: '60px', width: '60px' }}></img>
              </div>


            </CardContent>

          </Card>
        </Grid>
        <Grid item sm={3}>
          <Card
            sx={{
              width: "270px",
              height: "110px",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: 0,
            }}
          >
            <CardContent>



              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                    Doctors/Clinicians{" "}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "28px", fontWeight: 700, marginTop: '10px' }}
                    color="#202224"
                  >
                    2,000
                  </Typography>
                </div>
                <img src={userIcon} style={{ height: '60px', width: '60px' }}></img>
                {/* <PeopleAltIcon style={{ height: '34px', width: '42', color: '#246AF1', backgroundColor: '#8280FF', borderRadius: '10px' }} /> */}
              </div>

            </CardContent>

          </Card>
        </Grid>
        <Grid item sm={3}>
          <Card
            sx={{
              width: "270px",
              height: "110px",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: 0,
            }}
          >
            <CardContent>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                    Nurses{" "}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "28px", fontWeight: '700', marginTop: '10px' }}
                    color="#202224"
                  >
                    5,000
                  </Typography>
                </div>
                <img src={userIconYellow} style={{ height: '60px', width: '60px' }}></img>
                {/* <PeopleAltIcon style={{ height: '34px', width: '42', color: '#246AF1', backgroundColor: '#8280FF', borderRadius: '10px' }} /> */}
              </div>

            </CardContent>

          </Card>
        </Grid>
        <Grid item sm={3}>
          <Card
            sx={{
              width: "270px",
              height: "110px",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: 0,
            }}
          >
            <CardContent>



              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                    Allied Healthcare{" "}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "28px", fontWeight: '700', marginTop: '10px' }}
                    color="#202224"
                  >
                    1,000
                  </Typography>
                </div>
                <img src={userIconPink} style={{ height: '60px', width: '60px' }}></img>
              </div>

            </CardContent>

          </Card>
        </Grid>



      </Grid>
    </div>
  );
}
