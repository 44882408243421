import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { alpha, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import * as React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import InternalImage from "./Icons/internal.png";
import GeneralImage from "./Icons/general.png";
import OrthoImage from "./Icons/ortho.png"


import { Grid } from '@mui/material';
import DepartmentsIcon from "./Icons/DepartmentsIcon.svg";
import SystemIcon from "./Icons/SystemIcon.svg";
import { borderRadius, height } from '@mui/system';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '16px',
    borderColor: '#D4D4D4',
    border: '1px',
    backgroundColor: "#F5F6FA",
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: '80%',

    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'black',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '40ch',
        },
    },
}));

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));

export default function RecipeReviewCard() {
    const [expanded, setExpanded] = React.useState(false);
    let navigate = useNavigate();


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '1140px' }}>
            <Card sx={{ width: "554px", height: '350px', borderRadius: '10px' }} elevation={0}>
                <CardHeader
                    avatar={
                        // <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        <img src={DepartmentsIcon} style={{ height: '50px', width: '50px' }}></img>
                        // </Avatar>
                    }
                    action={
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon style={{ color: '#000000' }} />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search', color: '#000000' }}
                            />
                        </Search>
                    }
                    title={
                        <Typography style={{
                            color: '#202224',
                            fontWeight: 700,
                            fontSize: '16px'
                        }}>
                            Departments
                        </Typography>
                    }
                    subheader={
                        <Typography style={{
                            color: '#A7A7A7',
                            fontWeight: 500,
                            fontSize: '12px'
                        }}>
                            (40)
                        </Typography>
                    }
                />

                <CardContent>
                    <div style={{ height: '220px', overflow: 'scroll', overflowX: 'hidden' }}>
                        <List style={{ cursor: 'pointer' }} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem onClick={() => {
                                navigate("/network/branch/department")
                            }}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img src={OrthoImage}></img>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={
                                    <Typography style={{
                                        color: '#202224',
                                        fontWeight: 500,
                                        fontSize: '16px'
                                    }}>
                                        Orthopedic Department
                                    </Typography>
                                } secondary={
                                    <Typography style={{
                                        color: '#A7A7A7',
                                        fontWeight: 400,
                                        fontSize: '12px'
                                    }}>
                                        21987836
                                    </Typography>
                                } />
                            </ListItem>
                            <ListItem onClick={() => {
                                navigate("/network/branch/department")
                            }}>                                    <ListItemAvatar>
                                    <Avatar>
                                        <img src={GeneralImage}></img>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={
                                    <Typography style={{
                                        color: '#202224',
                                        fontWeight: 500,
                                        fontSize: '16px'
                                    }}>
                                        General Surgery Department
                                    </Typography>
                                } secondary={
                                    <Typography style={{
                                        color: '#A7A7A7',
                                        fontWeight: 400,
                                        fontSize: '12px'
                                    }}>
                                        21987836
                                    </Typography>
                                } />
                            </ListItem>
                            <ListItem onClick={() => {
                                navigate("/network/branch/department")
                            }}>                                    <ListItemAvatar>
                                    <Avatar>
                                        <img src={InternalImage}></img>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={
                                    <Typography style={{
                                        color: '#202224',
                                        fontWeight: 500,
                                        fontSize: '16px'
                                    }}>
                                        Internal Medicine Department
                                    </Typography>
                                } secondary={
                                    <Typography style={{
                                        color: '#A7A7A7',
                                        fontWeight: 400,
                                        fontSize: '12px'
                                    }}>
                                        21987836
                                    </Typography>
                                } />
                            </ListItem>
                            <ListItem onClick={() => {
                                navigate("/network/branch/department")
                            }}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img src={"https://tse3.mm.bing.net/th?id=OIP.MO-j33vjm66ggp-9CYW83wHaEK&pid=Api&P=0&h=180"}></img>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography style={{
                                            color: '#202224',
                                            fontWeight: 500,
                                            fontSize: '16px'
                                        }}>
                                            Cardiology Department
                                        </Typography>
                                    } secondary={
                                        <Typography style={{
                                            color: '#A7A7A7',
                                            fontWeight: 400,
                                            fontSize: '12px'
                                        }}>
                                            21987836
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img src={"https://tse3.mm.bing.net/th?id=OIP.MO-j33vjm66ggp-9CYW83wHaEK&pid=Api&P=0&h=180"}></img>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography style={{
                                            color: '#202224',
                                            fontWeight: 500,
                                            fontSize: '16px'
                                        }}>
                                            Ophthalmology Department
                                        </Typography>
                                    } secondary={
                                        <Typography style={{
                                            color: '#A7A7A7',
                                            fontWeight: 400,
                                            fontSize: '12px'
                                        }}>
                                            21987836
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </div>
                </CardContent>

            </Card>

            <Card sx={{ width: "554px", height: '350px', borderRadius: '10px' }} elevation={0}>
                <CardHeader
                    avatar={
                        // <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        <img src={SystemIcon} style={{ height: '50px', width: '50px' }}></img>
                        // </Avatar>
                    }
                    action={
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon style={{ color: '#000000' }} />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search', color: '#000000' }}
                            />
                        </Search>
                    }
                    title={
                        <Typography style={{
                            color: '#202224',
                            fontWeight: 700,
                            fontSize: '16px'
                        }}>
                            Systems
                        </Typography>
                    }
                    subheader={
                        <Typography style={{
                            color: '#A7A7A7',
                            fontWeight: 500,
                            fontSize: '12px'
                        }}>
                            (2)
                        </Typography>
                    }
                />

                <CardContent>
                    <div style={{ height: '170px', overflow: 'scroll', overflowX: 'hidden' }}>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        1
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={
                                    <Typography style={{
                                        color: '#202224',
                                        fontWeight: 400,
                                        fontSize: '16px'
                                    }}>
                                        Cerner EHR
                                    </Typography>
                                } />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        2
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={
                                    <Typography style={{
                                        color: '#202224',
                                        fontWeight: 400,
                                        fontSize: '16px'
                                    }}>
                                        Oracle HR
                                    </Typography>
                                } />
                            </ListItem>


                        </List>
                    </div>
                </CardContent>

            </Card>
        </div>

    );
}
