import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Chart from "react-apexcharts";
import "react-circular-progressbar/dist/styles.css";
import { Badge } from "@material-ui/core";
import CircleIcon from '@mui/icons-material/Circle';
import { Button } from "@material-ui/core";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar"; import 'react-circular-progressbar/dist/styles.css';
import { display, fontWeight } from "@mui/system";

const bull = (
    <Box
        component="span"
        sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
        •
    </Box>
);



export default function BasicCard({ data }) {
    const GraphOptions = {
        chart: {
            type: 'donut',
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]

    };
    const series = [44, 55, 41, 17, 15];
    var data = [
        { value: null, label: '' },
        { value: 5, label: 'ER' },
        { value: 10, label: 'Orthopaedic' },
        { value: 1, label: 'Cardiology' },
        { value: 19, label: 'General Surgery' },
        { value: 5, label: 'ICU' }
    ];
    const seriesLine = [
        {
            name: 'Departments',
            data: data.map(item => item.value)  // Extracting only the values for the series
        }
    ];
    const GraphOptionsLine = {
        chart: {
            type: 'bar',
            height: 300
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                dataLabels: {
                    position: 'top' // Show labels at the top of bars
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                // Use the index from the 'opts' object to reference the original object array
                var index = opts.dataPointIndex;
                return data[index].label;  // Return the label instead of the value
            },
            offsetY: -30,
            style: {
                fontSize: '12px',
                fontWeight: 600,
                colors: ["#292C2F66"],
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                distributed: true,  // This enables different colors for each bar
                dataLabels: {
                    position: 'top'  // Ensure labels are positioned at the top of the bars
                }
            }
        },
        colors: ['#00B69B', '#4880FF'],
        legend: {
            show: false
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#00B69B', '#4880FF']
        },
        // xaxis: {
        //     categories: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        //     position: 'bottom'
        // },
        // yaxis: {
        //     title: {
        //         text: ''
        //     }
        // },
        fill: {
            opacity: 1
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val + " min"; // Adds "min" to each value on the y-axis
                }
            },
            // title: {
            //     text: 'Time (minutes)'
            // }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + " min";
                }
            }
        }
    };
    // const GraphOptionsLine = {
    //     chart: {
    //         type: "bar",
    //         zoom: {
    //             enabled: false,
    //         },
    //     },
    //     noData: {
    //         text: "No Data Found",
    //         align: "center",
    //         verticalAlign: "middle",
    //         offsetX: 0,
    //         offsetY: 0,
    //         style: {
    //             color: undefined,
    //             fontSize: "20px",
    //             fontFamily: undefined,
    //         },
    //     },
    //     dataLabels: {
    //         enabled: false,
    //     },
    //     xaxis: {
    //         categories: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov'],
    //     },
    //     markers: {
    //         size: 5,
    //         colors: undefined,
    //         strokeColors: '#fff',
    //         strokeWidth: 5,
    //         strokeOpacity: 0.9,
    //         strokeDashArray: 0,
    //         fillOpacity: 3,
    //         discrete: [],
    //         shape: "circle",
    //         offsetX: 0,
    //         offsetY: 0,
    //         onClick: undefined,
    //         onDblClick: undefined,
    //         showNullDataPoints: true,
    //         hover: {
    //             size: undefined,
    //             sizeOffset: 3
    //         }
    //     },
    //     colors: ["#4880FF", "#00B69B"],
    //     stroke: {
    //         show: true,
    //         curve: "smooth",
    //         lineCap: "butt",
    //         colors: undefined,
    //         width: 1,
    //         dashArray: 0,
    //     },
    //     style: {
    //         fontSize: "12px",
    //         fontFamily: "Helvetica Neue, sans-serif",
    //         fontWeight: 500,
    //         colors: ["#4880FF", "#00B69B"]
    //     },
    //     grid: {
    //         show: true,
    //         borderColor: "#EBEDF7",
    //         strokeDashArray: 0,
    //         position: "back",
    //         xaxis: {
    //             lines: {
    //                 show: true,
    //             },
    //         },
    //         yaxis: {
    //             lines: {
    //                 show: false,
    //             },
    //         },
    //     },

    // };
    return (
        <Grid container spacing={2}>
            <Grid item sm={6}>
                <Card
                    sx={{
                        minWidth: "575px",
                        height: "400px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: 0,
                    }}
                >
                    <CardContent>
                        <Typography style={{ fontSize: "24px", fontWeight: '700' }}>
                            Acceptance of Referrals{" "}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>

                            <div style={{ width: 150, height: 150, marginTop: '20px' }}>
                                <CircularProgressbar
                                    value={80}
                                    styles={buildStyles({
                                        pathColor: "#246AF1",
                                        textColor: '#f88',
                                        trailColor: '#C0D2F0',
                                        rotation: 0.5 + (1 - 66 / 100) / 2
                                    })}
                                />


                            </div>

                            {/* <Chart
                                options={GraphOptions}
                                series={series}
                                type="donut"
                                width="90%"
                                height="80%"
                            /> */}
                        </div>
                        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                            <Grid container spacing={2} style={{ width: '60%' }} >
                                <Grid item sm={6}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                                        <Typography style={{
                                            fontSize: '28px',
                                            fontWeight: '700',
                                            color: '#202224',
                                        }}>
                                            1200
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography style={{
                                            fontSize: '28px',
                                            fontWeight: '700',
                                            color: '#202224'
                                        }}>
                                            150
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <CircleIcon style={{ color: '#4880FF', height: '12px', width: '12px', marginTop: '5px', marginRight: '5px' }} />
                                        <Typography style={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            color: '#282D32'
                                        }}>
                                            Accepted
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item sm={6}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <CircleIcon style={{ color: '#C0D2F0', height: '12px', width: '12px', marginTop: '5px', marginRight: '5px' }} />
                                        <Typography style={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            color: '#282D32'
                                        }}>
                                            Rejected
                                        </Typography>
                                    </div>

                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={6}>
                <Card
                    sx={{
                        minWidth: "575px",
                        height: "400px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: 0,
                    }}
                >
                    <CardContent>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ fontSize: "24px", fontWeight: '700' }} color="#202224">
                                Referral Response Time
                                {" "}
                            </Typography>
                            <Button variant='outlined'
                                endIcon={
                                    <KeyboardArrowDownIcon />
                                }
                                disableElevation
                                style={{ backgroundColor: '#FCFDFD', borderColor: '#D4D4D4', textTransform: 'none', color: 'black' }}>
                                <Typography>
                                    October
                                </Typography>
                            </Button>
                        </div>
                        <div>
                            <Chart
                                options={GraphOptionsLine}
                                series={seriesLine}
                                type="bar"
                                width="100%"
                            />
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
