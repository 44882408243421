import {
    Avatar,
    Button,
    TableCell,
    Typography
} from "@mui/material";
import Divider, { dividerClasses } from '@mui/material/Divider';

import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from "react-router-dom";
import deleteButton from "./../Network/Icons/deleteButton.svg";
// import BroadCastAdd from "./BroadcastAdd"
import editButton from "./../Network/Icons/editButton.svg";

// import { DataGrid } from '@mui/x-data-grid';
import FilterIcon from "./../Network/Icons/filterIcon.svg";

import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper, TableRow } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import useGeneralStyle from "../../assets/css/general";
import Datatables from "../../components/Datatables";
import { apiClient } from "../../config/apiClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import { inviteAdminValidation } from "../../validations/login-validate";

import { Box } from "@mui/system";
//   import SuspendAdminModal from "./SuspendAdminModal";
import { KeyboardArrowDown, RefreshSharp, RestartAlt } from "@mui/icons-material";
import Checkbox from '@mui/material/Checkbox';
import { useAuth } from "../../providers/AuthProvider";

const tableHeader = [
    { id: "CreatedAt", align: "left", label: "Select", minWidth: 60 },
    { id: "CreatedAt", align: "left", label: "Role Name", minWidth: 140 },
    { id: "CreatedAt", align: "left", label: "No. of Users", minWidth: 100 },
    { id: "FirstName", align: "left", label: "No. of Policies", minWidth: 150 },
    { id: "LastName", align: "left", label: "Attached Policies", minWidth: 100 },
    { id: "Status", align: "left", label: "Actions", minWidth: 100 },
];

// let diffTableHeader = [...tableHeader];
// diffTableHeader.splice(4, 1);

const useStyles = makeStyles((theme2) => ({
    formControl: {
        margin: theme2.spacing(1),
        minWidth: 160,
        color: "white",
        borderBottom: "none",
    },
    cell: {
        fontSize: '14px',
        fontWeight: 400,
    },
    selectEmpty: {
        marginTop: theme2.spacing(2),
    },
    statusBox: {
        width: "150px",
        borderRadius: "5px",
        padding: "5px",
        textAlign: "center",
        fontWeight: "bold",
    },
    icon: {
        fill: "white",
    },
    root: {
        width: "100%",
        overflowX: "auto",
        borderRadius: '12px'
        // marginTop: '25px',
    },
    suspendedStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #fd6868",
        backgroundColor: "#ffc3c3",
        color: "#e64848",
        fontWeight: "bold",
    },
    suspendedStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #fd6868",
        backgroundColor: "#ffc3c3",
        color: "#e64848",
        fontWeight: "bold",
    },
    pendingStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #eab20a",
        backgroundColor: "#ffeeb5",
        color: "#eab20a",
        fontWeight: "bold",
    },
    registeredStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #6ac358",
        backgroundColor: "#c3f4ba",
        color: "#6ac358",
        fontWeight: "bold",
    },
    container: {
        height: "440px",
        width: "100%",
    },
    activeFilter: {
        // backgroundColor: theme2.palette.primary.light,
        borderBottom: "2px solid white ",
        borderRadius: "0px",
        color: "white",
        fontWeight: 600,
    },
    icon: {
        fill: "#FFFFFF",
    },
    carddelModal: {
        width: "500px",
        borderRadius: "12px",
        backgroundColor: "#1b1f3b",
        padding: "1rem",
        position: "relative",

        [theme2.breakpoints.down("sm")]: {
            width: "90vw !important",
        },

        "&:focus-visible": {
            outline: "none",
        },
    },
    profileData: {
        color: "white",
        paddingTop: "2px",
        fontSize: "16px",
        // fontWeight: 600,
    },
    profileHeading: {
        fontSize: "12px",
        // fontWeight: 600,
        color: "#B0B2B6",
        textAlign: "left",
    },

    parentofappointmentcontent: {
        [theme2.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
    },
    bottombox: {
        [theme2.breakpoints.down("xs")]: {
            marginLeft: "0 !important",
            display: "flex",
            justifyContent: "flex-start !important",
            alignItems: "flex-start !important",
        },
    },
    doublerarrow: {
        [theme2.breakpoints.down("xs")]: {
            transform: "rotate(90deg)",
            margin: "1rem !important",
        },
    },

    tabsContainer: {
        backgroundColor: "#fff",
        margin: "20px 20px 0px 20px",
        height: "44px",
        [theme2.breakpoints.down("md")]: {
            width: "95%",
            overflow: "scroll",
        },
        [theme2.breakpoints.down("sm")]: {
            width: "88%",
            overflow: "scroll",
        },
        "&::-webkit-scrollbar": {
            height: "0",
        },
    },
}));



const AdminUsersTable = () => {
    const globalClasses = useGeneralStyle();
    const classes = useStyles();
    const getAdminListStatus = useCallbackStatus();
    const getUpdateAdminStatus = useCallbackStatus();
    const inviteAdminStatus = useCallbackStatus();
    const navigate = useNavigate();

    const apiSource = useCancelRequest();
    const notification = useSnackbar();
    const [search, setSearchKey] = useState("");
    const [adminListData, setAdminListData] = useState([]);
    const [currentStatus, setCurrentStatus] = useState("approved");
    const [filter, setFilter] = useState("approved");
    const [modal, setModal] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [adminComment, setAdminComment] = useState("");
    const [reason, setReason] = useState(false);
    const [rowId, setRowId] = useState(null);
    const [role_id, setRoleId] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const data = [{
        role_name: 'Admin',
        no_of_users: '1',
        no_of_policies: '3',
        data: ['Profession Settings', 'User Management', 'Admin']
    },
    {
        role_name: 'Super Admin',
        no_of_users: '1',
        no_of_policies: '1',
        data: ['All']


    },
    {
        role_name: 'Branch Admin',
        no_of_users: '1',
        no_of_policies: '3',
        data: ['Branch Management', 'User Management', 'Admin']


    },
    {
        role_name: 'Supervisor',
        no_of_users: '1',
        no_of_policies: '1',
        data: ['Broadcast']


    },


    ]
    const auth = useAuth();
    const {
        values,
        touched,
        errors,
        setValues,
        handleBlur,
        submitForm,
        handleChange,
        resetForm,
    } = useFormik({
        initialValues: {
            email: "",
            first_name: "",
            last_name: "",
            role: null,
            password: null,
        },
        validationSchema: inviteAdminValidation,
        onSubmit: () => {
            // console.table(values);
            inviteAdmin(values);
            // setValues(null);
        },
    });
    let inviteAdmin = async (values) => {
        await inviteAdminStatus.run(
            apiClient("POST", "admin", "manageadmin", {
                body: {
                    role_id: values.role,
                    action: "create",
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    password: values.password,
                },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data },
                    } = result;
                    console.log("=======", data);
                    setModal(!modal);
                    notification.enqueueSnackbar("Admin Invited Successfully", {
                        variant: "success",
                    });
                    resetForm();
                    getAdminList(null, true);
                })
                .catch((error) => {
                    notification.enqueueSnackbar(error.message, {
                        variant: "error",
                    });
                })
        );
    };
    const updateAdminList = async (Id, action) => {
        console.log("id adminlist", Id);

        let apiBody = {};

        // const updateAdminList = async (Id, action) => {
        //   let apiBody = {};
        apiBody = {
            action_user_type: "admin",
            action_user_id: Id,
            action: action,
            comment: adminComment,
        };

        console.log("updateAdminList apibody", apiBody);

        if (currentStatus !== "suspended") {
            delete apiBody.comment;
        }

        try {
            const res = await getUpdateAdminStatus.run(
                apiClient("POST", "admin", "userstatusactions", {
                    body: { ...apiBody },
                    shouldUseDefaultToken: false,
                    cancelToken: apiSource.token,
                    enableLogging: true,
                })
            );

            const {
                content: { data },
            } = res;

            console.log("check updatedData", data);

            getAdminList(null, true);
        } catch (err) {
            if (err && err.code === 403) {
                // logout();
                console.log("error", err);
            } else {
                notification.enqueueSnackbar(err.message, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
            }
        }
    };

    const getAdminList = async (token, refresh = false) => {
        let apiBody = {
            pagination_required: true,
            next_token: token,
        };

        apiBody = {
            search_string: search,
            status: filter,
            search_fields: ["first_name", "last_name", "email"],
        };

        if (apiBody.search_string === "") {
            delete apiBody.search_string;
        }

        try {
            const res = await getAdminListStatus.run(
                apiClient("POST", "admin", "getadminbyfilter", {
                    body: { ...apiBody },
                    shouldUseDefaultToken: false,
                    cancelToken: apiSource.token,
                    enableLogging: true,
                })
            );

            const {
                content: { data, has_more, next_token },
            } = res;
            console.log(data)
            if (refresh) setAdminListData([...data]);
            else {
                setAdminListData([...adminListData, ...data]);
            }
            setHasMore(has_more);
            setTotalPages(next_token);
        } catch (err) {
            if (err && err.code === 403) {
                // logout();

                console.log("error", err);
            } else {
                notification.enqueueSnackbar(err.message, {
                    variant: "err",
                    autoHideDuration: 2000,
                });
            }
        }
    };

    let getAdminRoles = async () => {
        await getAdminListStatus.run(
            apiClient("POST", "common", "manageentities", {
                body: {
                    action: "list",
                    entity_type: "role",
                },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data },
                    } = result;
                    console.log(data);
                    setRoleList(data);
                })
                .catch((error) => {
                    notification.enqueueSnackbar(error.message, {
                        variant: "error",
                    });
                })
        );
    };
    const handleSuspendAction = (rowId) => {
        setRowId(rowId);
        setReason(true);
    };

    useEffect(() => {
        getAdminList(null, true);
    }, [currentStatus, filter]);
    useEffect(() => {
        getAdminRoles();
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getAdminList(null, true);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    const ModalHeader = () => {
        return (
            <>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    style={{ fontSize: "14px" }}
                >
                    <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
                        Invite New Admin Staff
                    </Box>
                    <Button
                        style={{ position: "absolute", right: "20px" }}
                        onClick={() => {
                            setModal(!modal);
                            setValues(null);
                            resetForm();
                        }}
                    >
                        <CloseIcon size="large" style={{ color: "white" }} />
                    </Button>
                    {/* <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
                To: {userObj.email}
              </Box> */}
                </Box>
            </>
        );
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'firstName', headerName: 'First name', width: 130 },
        { field: 'lastName', headerName: 'Last name', width: 130 },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            width: 90,
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
        },
    ];

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    const paginationModel = { page: 0, pageSize: 5 };

    const SmartBtn = ({ data }) => {
        let [showLess, setShowLess] = useState(true);

        if (data.length < 4) {
            return (
                <>
                    {data.map((item, index) => {
                        return (
                            <Button
                                key={index}
                                variant="outlined"
                                disableRipple
                                style={{
                                    borderRadius: "6px",
                                    border: "1px solid #00C3B8",
                                    backgroundColor: "#00C3B8",
                                    height: "30px",
                                    margin: "5px",
                                    color: "white",
                                    cursor: "default",
                                    textTransform: "none",
                                }}
                            >
                                {item}
                            </Button>
                        );
                    })}
                </>
            );
        }


    };


    return (
        <>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Typography
                        style={{
                            fontWeight: '400',
                            fontSize: '16px',
                            color: '#A7A7A7'
                        }}>
                        Admins
                    </Typography>
                    <Typography style={{
                        fontWeight: '700',
                        fontSize: '32px'
                    }}>
                        Role Management
                    </Typography>
                </div>
                <div>
                    <Box style={{ padding: '10px' }}>
                        {/* <BroadCastAdd /> */}
                        <Button variant="contained" disableElevation size="large" style={{ textTransform: 'none', backgroundColor: '#246AF1', color: 'white' }}>
                            Add new Role
                        </Button>
                    </Box>
                    {/* <AddNew /> */}
                    {/* <Button variant="contained" style={{ textTransform: 'none' }} onClick={(e) => {
              e.preventDefault();
              setModal(!modal);
            }}>
              Add New Branch
            </Button> */}
                </div>
            </Box>

            <Grid container>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 3,
                        bgcolor: 'background.paper',
                        color: 'text.secondary',
                        '& svg': {
                            m: 1,
                        },
                        [`& .${dividerClasses.root}`]: {
                            mx: 0.5,
                        },
                    }}
                >
                    <span
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "Center",
                            alignItems: "Center",
                            // marginLeft: "5px"
                        }}
                    >
                        <Checkbox />
                        <KeyboardArrowDown style={{ marginLeft: '-12px' }} />
                    </span>
                    <Divider orientation="vertical" flexItem />
                    <RefreshSharp style={{ color: '#202224' }} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 3,
                        marginLeft: '10px',
                        bgcolor: 'background.paper',
                        color: 'text.secondary',
                        '& svg': {
                            m: 1,
                        },
                        [`& .${dividerClasses.root}`]: {
                            mx: 0.5,
                        },
                    }}
                >
                    <div style={{ padding: '20px' }}>
                        <img src={FilterIcon}></img>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div style={{ padding: '20px' }}>
                        <Typography>
                            Filter By
                        </Typography>
                    </div>

                    {/* <Divider orientation="vertical" flexItem />
                    <span
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "Center",
                            alignItems: "Center",
                            // marginLeft: "5px"
                        }}
                    >
                        <Typography style={{ padding: '20px' }}>
                            14 Feb 2019
                        </Typography>
                        <KeyboardArrowDown />
                    </span> */}
                    <Divider orientation="vertical" flexItem />
                    <span
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "Center",
                            alignItems: "Center",
                            // marginLeft: "5px"
                        }}
                    >
                        <Typography style={{ padding: '20px' }}>
                            Admin
                        </Typography>
                        <KeyboardArrowDown />
                    </span>
                    <Divider orientation="vertical" flexItem />
                    <span
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "Center",
                            alignItems: "Center",
                            // marginLeft: "5px"
                        }}
                    >
                        <Typography style={{ padding: '20px' }}>
                            Status
                        </Typography>
                        <KeyboardArrowDown />
                    </span>
                    <Divider orientation="vertical" flexItem />
                    <span
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "Center",
                            alignItems: "Center",
                            // marginLeft: "5px"
                        }}
                    >

                        <RestartAlt style={{ color: '#EA0234' }} />
                        <Typography style={{ padding: '10px', color: "#EA0234" }}>
                            Reset Filter
                        </Typography>
                    </span>
                </Box>
                {/* <Box style={{ padding: '10px' }}>
                    <Button variant="contained" size="large" style={{ textTransform: 'none', backgroundColor: '#F29006', color: 'white' }}>
                        Download Template
                    </Button>
                </Box>
                <Box style={{ padding: '10px' }}>
                    <Button variant="contained" size="large" style={{ textTransform: 'none', backgroundColor: '#246AF1', color: 'white' }}>
                        Upload CSV
                    </Button>
                </Box> */}
                {/* <Grid
            container
            style={{ backgroundColor: "white", paddingLeft: "20px" }}
            className="ChangeRequestsContainer"
          >
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px",
              }}
              item
              xs={11}
              sm={4}
              md={4}
              lg={4}
            >
              <TextField
                onChange={(e) => setSearchKey(e.target.value)}
                variant="outlined"
                style={{ backgroundColor: "#F5F5F7", width: "400px" }}
                inputProps={{
                  style: { padding: "12px" },
                }}
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      variant="filled"
                      fontSize="small"
                      style={{ backgroundColor: "#F5F5F7", color: "#141522" }}
                    />
                  ),
                  className: "inputFont",
                }}
                placeholder="Search admin by name or email"
              />
            </Grid>
  
            <Grid
              style={{
                display: "flex",
                // alignItems: "center",
                padding: "10px 0px",
              }}
              item
              xs={11}
              sm={5}
              md={5}
              lg={5}
            >
              <Select
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                variant="outlined"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                style={{
                  marginLeft: "10px",
                  paddingRight: "20px",
                  backgroundColor: "#F5F5F7",
                }}
                className={globalClasses.selectInput}
              >
                <MenuItem key="approved" value="approved">
                  Approved
                </MenuItem>
                <MenuItem key="suspended" value="suspended">
                  Suspended
                </MenuItem>
              </Select>
            </Grid>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px",
              }}
              item
              xs={11}
              sm={2}
              md={2}
              lg={2}
            >
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setModal(!modal);
                }}
                variant="contained"
                style={{ width: "40%", backgroundColor: "#141522" }}
              >
                <Typography style={{ color: "white" }}>invite</Typography>
              </Button>
            </Grid>
          </Grid> */}
                <Grid item xs={12} style={{ paddingTop: "20px" }}>
                    <Paper className={classes.root} elevation={0}>
                        <Datatables
                            data={adminListData}
                            loading={getAdminListStatus.isPending}
                            getDataList={(token) => getAdminList(token, false)}
                            hasMore={hasMore}
                            nextToken={totalPages}
                            tableHeader={tableHeader}
                            orderByKey="name"
                            isEmpty={adminListData.length === 0}
                        >
                            {data.map((row, index) => {
                                return (

                                    <TableRow key={index} hover onClick={() => {
                                        // navigate('/network/branch/department/calender')

                                    }}>
                                        <TableCell style={{ minWidth: '' }} >
                                            <Checkbox />
                                        </TableCell>
                                        <TableCell style={{ minWidth: '' }} >
                                            <Typography className={classes.cell}>
                                                {row.role_name}
                                            </Typography >
                                        </TableCell>
                                        <TableCell >

                                            <Typography className={classes.cell}>
                                                {row.no_of_users}
                                            </Typography >
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={classes.cell}>
                                                {row.no_of_policies}
                                            </Typography >
                                        </TableCell>
                                        <TableCell>
                                            <SmartBtn data={row.data} />
                                        </TableCell>


                                        <TableCell >
                                            <Box
                                                sx={{
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    border: '1px solid',
                                                    borderColor: 'divider',
                                                    borderRadius: 2,
                                                    bgcolor: 'background.paper',
                                                    color: 'text.secondary',
                                                    '& svg': {
                                                        m: 2,
                                                    },
                                                }}
                                            >
                                                <img src={editButton}></img>
                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                <img src={deleteButton}></img>
                                            </Box>


                                        </TableCell>

                                    </TableRow>

                                )
                            })}
                        </Datatables>

                    </Paper>
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                        <Typography
                            style={{
                                fontSize: '14px'
                            }}>
                            Showing 1-09 of 78
                        </Typography>
                        <Box
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 2,
                                bgcolor: 'background.paper',
                                color: 'text.secondary',
                                '& svg': {
                                    m: 1,
                                },
                            }}
                        >
                            <ArrowBackIosIcon style={{ height: '15px', width: '15px' }} />
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <ArrowForwardIosIcon style={{ height: '15px', width: '15px' }} />
                        </Box>
                    </div> */}
                </Grid>
            </Grid>
        </>
    );
};

export default AdminUsersTable;
