import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useAuth } from "../../providers/AuthProvider";
import { apiClient } from "../../config/apiClient";
import { SnackbarProvider, useSnackbar } from "notistack";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import BlankImage from "./../Network/Icons/imageBlank.svg"
import { InputLabel, TextField, FormControl, Select, MenuItem, InputAdornment, CircularProgress, FormHelperText } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { makeStyles } from "@material-ui/core/styles";
import { fontSize } from '@mui/system';

import PhoneInput from 'react-phone-input-2'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import 'react-phone-input-2/lib/style.css'
import { useAsyncError } from 'react-router-dom';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    maxWidth: 'sm',
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));




export default function CustomizedDialogs({ getList }) {

    const [open, setOpen] = React.useState(false);
    let auth = useAuth();
    const notification = useSnackbar();
    const loginApiStatus = useCallbackStatus();
    const apiSource = useCancelRequest();
    const [titleError, setTitleError] = useState(false)
    const [desError, setDesError] = useState(false)

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("")


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        getList();
    };

    // let getList = async () => {

    //     await loginApiStatus.run(
    //         apiClient("POST", "settings", "managebroadcastmessages", {
    //             body: {
    //                 action: 'list'
    //             },
    //             shouldUseDefaultToken: false,
    //             cancelToken: apiSource.token,
    //             enableLogging: true,
    //         })
    //             .then((result) => {
    //                 console.log("list")
    //                 console.log(result)
    //             })
    //             .catch((error) => {

    //                 notification.enqueueSnackbar(error.message, {
    //                     variant: "error",
    //                 });
    //             })
    //     );
    // };

    // React.useEffect(() => {
    //     getList();
    // }, [])

    let create = async () => {
        if (!title || !description) {
            if (!title) {
                setTitleError("Enter Message Title")
            }
            if (!description) {
                setDesError("Enter Message")
            }
        }

        if (title && description) {
            setTitleError(false);
            setDesError(false)
            await loginApiStatus.run(
                apiClient("POST", "settings", "managebroadcastmessages", {
                    body: {
                        action: 'add',
                        title: title,
                        description: description
                    },
                    shouldUseDefaultToken: false,
                    cancelToken: apiSource.token,
                    enableLogging: true,
                })
                    .then((result) => {
                        console.log(result)
                        notification.enqueueSnackbar(result.message, {
                            variant: "success",
                        });
                        setTitle("");
                        setDescription("");
                        handleClose();
                    })
                    .catch((error) => {

                        notification.enqueueSnackbar(error.message, {
                            variant: "error",
                        });
                    })
            );
        }
    };

    return (
        <React.Fragment>

            <Button variant="contained" disableElevation style={{ textTransform: 'none' }}
                onClick={handleClickOpen}>
                <Typography style={{ color: 'white' }}>
                    Create new Broadcast
                </Typography>
            </Button>
            <Dialog
                fullWidth
                maxWidth="xs"
                PaperProps={{ sx: { borderRadius: "16px" } }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Typography
                        style={{ color: '#202224', fontSize: '18px', fontWeight: '700' }}>
                        Create Broadcast
                    </Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon style={{ color: '#EE2E31' }} />
                </IconButton>
                <DialogContent>
                    {/* <div style={{ display: 'flex' }}>
                        <img src={BlankImage}></img>
                        <Typography style={{ padding: '20px', color: '#246AF1' }}>
                            Upload Image
                        </Typography>
                    </div> */}
                    <div>
                        <Typography style={{
                            color: '#202224',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginTop: '20px',
                            marginBottom: '5px'
                        }}>
                            Select Branch*
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                fullWidth
                                InputProps={{ sx: { borderRadius: 3 } }}
                                borderRadius="5px"
                                size='small'
                                value={1}
                                style={{ backgroundColor: '#F1F4F9', marginBottom: '10px' }}

                            // value={age}
                            // onChange={handleChange}
                            >
                                <MenuItem value={1}>All</MenuItem>

                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <Typography style={{
                            color: '#202224',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginTop: '10px',
                            marginBottom: '5px'
                        }}>
                            Select Department*
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                fullWidth
                                InputProps={{ sx: { borderRadius: 3 } }}
                                borderRadius="5px"
                                size='small'
                                value={1}
                                style={{ backgroundColor: '#F1F4F9', marginBottom: '10px' }}

                            // value={age}
                            // onChange={handleChange}
                            >
                                <MenuItem value={1}>All</MenuItem>

                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <Typography style={{
                            color: '#202224',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginTop: '10px',
                            marginBottom: '5px'
                        }}>
                            Select Sub-Department*
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                fullWidth
                                InputProps={{ sx: { borderRadius: 3 } }}
                                borderRadius="5px"
                                size='small'
                                value={1}
                                style={{ backgroundColor: '#F1F4F9', marginBottom: '10px' }}

                            // value={age}
                            // onChange={handleChange}
                            >
                                <MenuItem value={1}>All</MenuItem>

                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <Typography style={{
                            color: '#202224',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginTop: '10px',
                            marginBottom: '5px'
                        }}>
                            Message Title*
                        </Typography>
                        <TextField
                            placeholder='Write Message Title'
                            fullWidth
                            borderRadius="5px"
                            InputProps={{ sx: { borderRadius: 3 } }}
                            value={title}
                            error={titleError}
                            onChange={(e) => {
                                if (e.target.value) {
                                    setTitle(e.target.value)
                                    setTitleError(false)
                                }
                                else {
                                    setTitle("")
                                    setTitleError("Enter Message Title")
                                }
                            }}
                            size='small'
                            required
                            style={{ backgroundColor: '#F1F4F9', marginBottom: '10px' }}
                        >

                        </TextField>
                        <FormHelperText style={{ color: 'red' }}>
                            {titleError}
                        </FormHelperText>
                        <Typography style={{
                            color: '#202224',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginTop: '10px',
                            marginBottom: '5px'
                        }}>
                            Message*
                        </Typography>
                        <TextField
                            placeholder='Write Message'
                            fullWidth
                            required
                            InputProps={{
                                sx: { borderRadius: 3 },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <AttachFileIcon />
                                    </InputAdornment>
                                )
                            }}
                            multiline
                            rows={6}
                            error={desError}
                            borderRadius="5px"
                            size='small'
                            value={description}
                            onChange={(e) => {
                                if (e.target.value) {
                                    setDescription(e.target.value)
                                    setDesError(false)

                                }
                                else {
                                    setDesError("Enter Message")
                                }
                            }}
                            style={{ backgroundColor: '#F1F4F9', marginBottom: '10px' }}

                        >

                        </TextField>
                        <FormHelperText style={{ color: 'red' }}>
                            {desError}
                        </FormHelperText>

                    </div>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button autoFocus style={{ textTransform: 'none' }} variant='contained' endIcon={<SendIcon />} onClick={() => {
                        create()
                    }}>
                        {
                            loginApiStatus.isPending ? (
                                <CircularProgress size={20} style={{ color: 'white' }} />
                            ) :
                                (
                                    "Send"
                                )
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
