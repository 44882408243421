import axios from "axios";
import jwt from "jsonwebtoken";
import {
  apiBasePath,
  apiDomainAdmin,
  apiDomainCommon,
  apiDomainSetting,
  apiDomainUser,
} from "./api-groups";
import { USER_EMAIL, USER_ID, USER_TOKEN } from "./constants";
import { decryptor, encryptor } from "./encryption-decryption";

// get user email
function getUserEmail() {
  return window.localStorage.getItem(USER_EMAIL);
}

// get user token
function getUserToken() {
  return window.localStorage.getItem(USER_TOKEN);
}

function getUserId() {
  return window.localStorage.getItem(USER_ID);
}

// sign jwt token
const signJwtToken = (body, shouldUseDefaultToken) => {
  // const { id, secret } = JSON.parse(getUserDetails());

  const key = shouldUseDefaultToken
    ? process.env.REACT_APP_DEFAULT_SECRET_KEY
    : getUserToken();

  const header = {
    alg: "HS256",
    typ: "JWT",
    channel: "web",
    user_id: getUserId(),
  };

  // if (!shouldUseDefaultToken) {
  //   header.device_id = DEVICE_ID.toString();
  // }

  if (shouldUseDefaultToken) {
    delete header.user_id;
  }

  let headerData = {
    user_type: "admin",
  };

  if (!shouldUseDefaultToken) {
    headerData.user_id = window.localStorage.getItem("userId");
  }
  console.log(headerData);
  console.log(header)
  console.log("check")
  return jwt.sign(
    {
      ...headerData,
    },
    key,
    {
      header,
    }
  );
};

const myInstance = axios.create();

const getBasePathName = (module) => {
  switch (module) {
    case "admin":
      return apiDomainAdmin;
    case "common":
      return apiDomainCommon;
    case "user":
      return apiDomainUser;
    case "settings":
      return apiDomainSetting;
  }
};

export function apiClient(
  method,
  basePathName,
  endPoint,
  {
    body,
    shouldUseDefaultToken = false,
    enableLogging = false,
    cancelToken = "",
    ...customConfig
  } = {}
) {
  // grab the base path
  const basePath = apiBasePath[basePathName];

  // const { id, secret, email } = JSON.parse(getUserDetails());

  // console.log(id, secret, email);

  // get the token
  const userToken = shouldUseDefaultToken
    ? process.env.REACT_APP_DEFAULT_SECRET_KEY
    : getUserToken();

  // encrypt the request

  const createBuffer = (apiBody) => {
    const encodeBuffer = Buffer.from(JSON.stringify(apiBody));
    return encodeBuffer + "";
  };

  // generate SHA2
  const generateSHA2 = (encodeBuffer) => {
    return encryptor(userToken, encodeBuffer);
  };
  console.log("body=>", body);
  // get buffer and signature
  const buffer = createBuffer(body);
  const signature = generateSHA2(buffer);
  const signedJwtToken = signJwtToken(body, shouldUseDefaultToken);
  // const signedJwtToken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3R5cGUiOiJhZG1pbiJ9.LgX4MKjVzWPHrjYhgucQVx4xs-C3nKFdEPIT2v5cZsI";
  // set the headers for request
  const headers = {
    "content-type": "application/json",
    channel: "web",
    // signature,
    user_id: getUserId(),
    Authorization: `Bearer ${signedJwtToken}`,
  };

  // if the api client is being called after login then also inclue the email in header
  if (!shouldUseDefaultToken) {
    const userEmail = getUserEmail();
    // const userId = getUserId();

    if (userEmail) {
      headers.email = userEmail;
      // headers.userId = userId;
    }
  }

  // api payload log
  if (enableLogging) {
    console.group(`API Body & SHA2 [${endPoint}]`);
    console.log(body);
    console.log(signature);
    console.groupEnd(`API Body & SHA2 [${endPoint}]`);
  }

  // create axios config
  const config = {
    // request method
    method,
    // base URL
    baseURL: getBasePathName(basePathName),
    // request url
    url: `${basePath}/${endPoint}`,
    // url: `${basePath}/${endPoint}`,

    // customconfig
    ...customConfig,
    // headers
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    responseType: "json", // default
    // timeout
    timeout: customConfig.timeout ? customConfig.timeout : 0,
    cancelToken,
  };

  // append the encrypted api body
  config.data = {
    params: signature,
  };

  if (!body) {
    delete config.data;
  }
  // return promise
  return myInstance(config)
    .then((response) => {
      // api response log
      if (enableLogging) {
        console.group("API Response");
        console.log(response);
        console.groupEnd("API Response");
      }
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      const { data } = response;
      // if(url.)
      if (data.content) {
        const password = shouldUseDefaultToken
          ? process.env.REACT_APP_DEFAULT_SECRET_KEY
          : getUserToken();

        try {
          const encryptedRes = jwt.decode(data.content);
          // debugger
          const decryptedData = decryptor(password, encryptedRes.params);
          const parsedObj = JSON.parse(decryptedData);

          // api response log
          if (enableLogging) {
            console.group("API Decrypted Response");
            console.log(parsedObj);
            console.groupEnd("API Decrypted Response");
          }

          // check if there are no errors

          const { error, code, content } = parsedObj;

          // check for errors and status code
          if (
            !error &&
            code >= 200 &&
            code < 300 &&
            content &&
            content.data !== null
          ) {
            return parsedObj;
          } else {
            return Promise.reject({ code: code, message: error.message });
          }
        } catch (err) {
          return Promise.reject({
            code: 403,
            message: "Invalid token",
          });
        }
      } else if (data.content === null && data.code && data.error) {
        return Promise.reject({ ...data.error });
      }
    })

    .catch((error) => {
      if (error?.response?.status === 403) {
        // localStorage.clear();
        // window.location.href = "/";
      }

      // check for errorHandle config
      if (
        error.config &&
        error.config.hasOwnProperty("errorHandle") &&
        error.config.errorHandle === false
      ) {
        return Promise.reject(error);
      }

      // error log
      if (enableLogging) {
        console.group("API Error");
        console.error(error);
        console.groupEnd("API Error");

        // alert(error.message)
        // notification.enqueueSnackbar(error.message, {
        //   variant: "error",
        //   autoHideDuration: 2000,
        // });
      }

      let err = error;

      if (error.response && error.response.status) {
        // err = JSON.stringify();
        err = {
          code: error.response.status,
          message: error.message,
        };
      } else if (!error.response) {
        err = {
          ...error,
          code: 500,
          message: error.message,
        };
        // err = JSON.stringify({
        //   code: 500,
        //   message: error.message,
        // });
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(err);
    });
}
