// App.js
import React from "react";
import {
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import { MenuContext } from "../context/MenuContext";
import Feedback from "../pages/Feedback";
import Demo from "../pages/ScheduleDemo";
import ThankYou from "../pages/Feedback/ThankYou.js";
import ApprovedMail from "../pages/Mails/ApprovedMail";
import RejectedMail from "../pages/Mails/RejectedMain";
import Department from "../pages/Network/DepartmentPage.js";
import NetworkPageManagement from "../pages/Network/NetworkPage.js";
import { useAuth } from "../providers/AuthProvider";
import DepartmentCalender from "../pages/Network/DepartmentCalender/index.js"
import Accessibility from "../pages/Accessibility/Accessibility";
import Admin from "../pages/Admin/index.js"
import PublicTopics from "../pages/Broadcast/Public.js";
import Analytics from "../pages/Ananlytics/index.js"
import BroadCast from "../pages/Broadcast/Broadcast.js"
import Announcements from "../pages/Announcements/index";
import About from "../pages/CMS/About";
import ContactUs from "../pages/CMS/ContactUs";
import PrivacyPolicy from "../pages/CMS/PrivacyPolicy";
import TermsAndConditions from "../pages/CMS/TermsAndConditions";
import Dashboard from "../pages/Dashboard/Dashboard";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Login from "../pages/Login/Login";
import Management from "../pages/Management/Management";
import BranchManagement from "../pages/Network/BranchManagement";
import Operation from "../pages/Operation/Operation";
import Overview from "../pages/Overview/Overview";
import Profile from "../pages/Profile/Profile";
import Settings from "../pages/Settings/Settings";

import OtpVerification from "../pages/Login/OtpVerification";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import UserRequest from "../pages/Tickets/Professional";
import SupportTickets from "../pages/Tickets/Tickets";

import AccountDelete from "../pages/AccountDelete/AccountDelete";
import AccountDeleteSuccess from "../pages/AccountDelete/AccountDeleteSuccess";
import Group from "../pages/DeepLinking/Groups";
import Professional from "../pages/DeepLinking/Professional";
import RoleManagement from "../pages/RoleManagement/RoleManagement";
import Roles from "../pages/Admin/Roles.js"


function RouteList() {
  let auth = useAuth();

  return (
    <Routes>
      {auth.getUserId() ? (
        <>
          <Route path="/" element={<Navigate replace to="/overview" />} />
          <Route path="/*" element={<Navigate replace to="/overview" />} />
          <Route
            path="/overview"
            element={
              <MenuContext>
                <Dashboard>

                  <Overview />
                </Dashboard>
              </MenuContext>
            }
          />
          {/* <Route
            path="/groups"
            element={
              <MenuContext>
                <Dashboard>
                  <Groups />
                </Dashboard>
              </MenuContext>
            }
          /> */}
          <Route
            path="/announcements"
            element={
              <MenuContext>
                <Dashboard>
                  <Announcements />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/profile"
            element={
              <MenuContext>
                <Dashboard>
                  <Profile />
                </Dashboard>
              </MenuContext>
            }
          />

          <Route
            path="/user_requests"
            element={
              <MenuContext>
                <Dashboard>
                  <UserRequest />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/support_tickets"
            element={
              <MenuContext>
                <Dashboard>
                  <SupportTickets />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/role_access"
            element={
              <MenuContext>
                <Dashboard>
                  <RoleManagement />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/company/termsofuse"
            element={
              <MenuContext>
                <Dashboard>
                  <TermsAndConditions />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/company/contact"
            element={
              <MenuContext>
                <Dashboard>
                  <ContactUs />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/company/about"
            element={
              <MenuContext>
                <Dashboard>
                  <About />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/company/privacy-policy"
            element={
              <MenuContext>
                <Dashboard>
                  <PrivacyPolicy />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/overview"
            element={
              <MenuContext>
                <Dashboard>
                  <Overview />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/users"
            element={
              <MenuContext>
                <Dashboard>
                  <Management />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/admin/admin"
            element={
              <MenuContext>
                <Dashboard>
                  <Admin />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/analytics"
            element={
              <MenuContext>
                <Dashboard>
                  <Analytics />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/broadcast"
            element={
              <MenuContext>
                <Dashboard>
                  {/* BroadCasts */}
                  <BroadCast />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/billing"
            element={
              <MenuContext>
                <Dashboard>
                  Billing
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/network/branch"
            element={
              <MenuContext>
                <Dashboard>
                  <BranchManagement />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/network/branch/department"
            element={
              <MenuContext>
                <Dashboard>
                  <Department />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/network/branch/department/calender"
            element={
              <MenuContext>
                <Dashboard>
                  <DepartmentCalender />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/network/branch/page"
            element={
              <MenuContext>
                <Dashboard>
                  <NetworkPageManagement />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/broadcast/public"
            element={
              <MenuContext>
                <Dashboard>
                  <PublicTopics />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/admin/role"
            element={
              <MenuContext>
                <Dashboard>
                  <Roles />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/operation"
            element={
              <MenuContext>
                <Dashboard>
                  <Operation />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/accessibility"
            element={
              <MenuContext>
                <Dashboard>
                  <Accessibility />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route
            path="/settings"
            element={
              <MenuContext>
                <Dashboard>
                  <Settings />
                </Dashboard>
              </MenuContext>
            }
          />
          <Route path="/approvedMails/:name" element={<ApprovedMail />} />
          <Route path="/rejectedMails/:name" element={<RejectedMail />} />

          <Route
            path="/professional/:professional_id"
            element={<Professional />}
          />
          <Route path="/group/:search_id" element={<Group />} />
        </>
      ) : (
        <>
          <Route path="/*" element={<Navigate replace to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/delete" element={<AccountDelete />} />
          <Route path="/delete-success" element={<AccountDeleteSuccess />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/approvedMails/:name" element={<ApprovedMail />} />
          <Route path="/rejectedMails/:name" element={<RejectedMail />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/otp-verification" element={<OtpVerification />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/schedule_demo" element={<Demo />} />
          <Route path="/ThankYou" element={<ThankYou />} />


          <Route path="/*" element={<Navigate replace to="/login" />} />
          <Route
            path="/professional/:professional_id"
            element={<Professional />}
          />
          <Route path="/group/:search_id" element={<Group />} />
        </>
      )}
    </Routes>
  );
}

export default RouteList;
