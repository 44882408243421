import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import userIcon from "../../assets/Dashboard/userIcon.svg";
import networkIcon from "../../assets/Dashboard/networkIcon.svg";
import dutyIcon from "../../assets/Dashboard/dutyIcon.svg";
import broadcastIcon from "../../assets/Dashboard/broadcastIcon.svg";


export default function BasicCard({ data }) {
  return (
    <Grid container spacing={2}>
      <Grid item sm={3}>
        <Card
          sx={{
            width: "100%",
            minWidth: "280px",
            height: "200px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: 0,
          }}
        >
          <CardContent>



            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Total Members{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: "28px", fontWeight: 700 }}
                  color="#202224"
                  style={{ display: "flex", margin: '10px 0px 10px' }}
                >
                  {/* {data[0].total_tickets} */}
                  40,689
                </Typography>
              </div>
              <img src={userIcon} style={{ height: '60px', width: '60px' }}></img>

              {/* <PeopleAltIcon style={{ height: '34px', width: '42', color: '#246AF1', backgroundColor: '#8280FF', borderRadius: '10px' }} /> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Active
                </Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#00C3B8" gutterBottom>
                  30,000
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Inactive
                </Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#FD096F" gutterBottom>
                  10,689
                </Typography>
              </div>

            </div>
            <div style={{ display: "flex", marginTop: '5px' }}>
              <TrendingUpIcon style={{ color: '#00C3B8' }} />

              <Typography style={{ marginLeft: '5px' }}>
                <span style={{ color: '#00C3B8' }}>8.5% </span>Up from Last Q3
              </Typography>

            </div>
          </CardContent>
          {/* <CardActions> */}
          <Box sx={{ flexGrow: 1, padding: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <div style={{ width: "68px", height: "68px" }}>
                    <CircularProgressbar
                      value={66}
                      text={`${66}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: "#546FFF",
                        trailColor: "#1a1e38",
                      })}
                    />{" "}
                  </div> */}
              </Grid>
              {/* <Grid item xs={6} sx={{ marginTop: "5%" }}>
                  <div>
                    <Typography sx={{ fontSize: "20px" }} color="white">
                      100
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }} color="#8E92BC">
                      Task
                    </Typography>
                  </div>
                </Grid> */}
            </Grid>
          </Box>
          {/* </CardActions> */}
        </Card>
      </Grid>

      <Grid item sm={3}>
        <Card
          sx={{
            width: "100%",
            minWidth: "280px",
            height: "200px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: 0,
          }}
        >
          <CardContent>



            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Number of Branches{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: "28px", fontWeight: 700 }}
                  color="#202224"
                  style={{ display: "flex", margin: '10px 0px 10px' }}
                >
                  12
                </Typography>
              </div>
              <img src={networkIcon} style={{ height: '60px', width: '60px' }}></img>
              {/* <PeopleAltIcon style={{ height: '34px', width: '42', color: '#246AF1', backgroundColor: '#8280FF', borderRadius: '10px' }} /> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Active
                </Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#00C3B8" gutterBottom>
                  10
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Inactive
                </Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#FD096F" gutterBottom>
                  2
                </Typography>
              </div>

            </div>
            <div style={{ display: "flex", marginTop: '5px' }}>
              <TrendingDownIcon style={{ color: '#FD096F' }} />

              <Typography style={{ marginLeft: '5px' }}>
                <span style={{ color: '#FD096F' }}>8.5% </span>Down from Last Q3
              </Typography>

            </div>
          </CardContent>
          {/* <CardActions> */}
          <Box sx={{ flexGrow: 1, padding: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <div style={{ width: "68px", height: "68px" }}>
                    <CircularProgressbar
                      value={66}
                      text={`${66}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: "#546FFF",
                        trailColor: "#1a1e38",
                      })}
                    />{" "}
                  </div> */}
              </Grid>
              {/* <Grid item xs={6} sx={{ marginTop: "5%" }}>
                  <div>
                    <Typography sx={{ fontSize: "20px" }} color="white">
                      100
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }} color="#8E92BC">
                      Task
                    </Typography>
                  </div>
                </Grid> */}
            </Grid>
          </Box>
          {/* </CardActions> */}
        </Card>
      </Grid>
      <Grid item sm={3}>
        <Card
          sx={{
            width: "100%",
            height: "200px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: 0,
          }}
        >
          <CardContent>



            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224">
                  Broadcast Engagement rate{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: "28px", fontWeight: 700 }}
                  color="#202224"
                  style={{ display: "flex" }}
                >
                  70%
                </Typography>
              </div>
              <img src={broadcastIcon} style={{ height: '60px', width: '60px' }}></img>
              {/* <PeopleAltIcon style={{ height: '34px', width: '42', color: '#246AF1', backgroundColor: '#8280FF', borderRadius: '10px' }} /> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Read
                </Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#00C3B8" gutterBottom>
                  1200
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Unread
                </Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#F29006" gutterBottom>
                  300
                </Typography>
              </div>

            </div>
            <div style={{ display: "flex", marginTop: '5px' }}>
              <TrendingUpIcon style={{ color: '#00C3B8' }} />

              <Typography style={{ marginLeft: '5px' }}>
                <span style={{ color: '#00C3B8' }}>8.5% </span>Up from Last week
              </Typography>

            </div>
          </CardContent>
          {/* <CardActions> */}
          <Box sx={{ flexGrow: 1, padding: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <div style={{ width: "68px", height: "68px" }}>
                    <CircularProgressbar
                      value={66}
                      text={`${66}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: "#546FFF",
                        trailColor: "#1a1e38",
                      })}
                    />{" "}
                  </div> */}
              </Grid>
              {/* <Grid item xs={6} sx={{ marginTop: "5%" }}>
                  <div>
                    <Typography sx={{ fontSize: "20px" }} color="white">
                      100
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }} color="#8E92BC">
                      Task
                    </Typography>
                  </div>
                </Grid> */}
            </Grid>
          </Box>
          {/* </CardActions> */}
        </Card>
      </Grid>

      <Grid item sm={3}>
        <Card
          sx={{
            width: "100%",
            minWidth: "280px",
            height: "200px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: 0,
          }}
        >
          <CardContent>



            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Total On-duty{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: "28px", fontWeight: 700 }}
                  color="#202224"
                  style={{ display: "flex", margin: '10px 0px 10px' }}
                >
                  150
                </Typography>
              </div>
              <img src={dutyIcon} style={{ height: '60px', width: '60px' }}></img>
              {/* <PeopleAltIcon style={{ height: '34px', width: '42', color: '#246AF1', backgroundColor: '#8280FF', borderRadius: '10px' }} /> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Day
                </Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#00C3B8" gutterBottom>
                  50
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "16px", fontWeight: '400' }} color="#202224" gutterBottom>
                  Night
                </Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: '500' }} color="#F29006" gutterBottom>
                  100
                </Typography>
              </div>

            </div>
            <div style={{ display: "flex", marginTop: '5px' }}>
              <TrendingUpIcon style={{ color: '#00C3B8' }} />
              <Typography style={{ marginLeft: '5px' }}>
                <span style={{ color: '#00C3B8' }}>8.5% </span>Up from Last week
              </Typography>
            </div>
          </CardContent>
          {/* <CardActions> */}
          <Box sx={{ flexGrow: 1, padding: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* <div style={{ width: "68px", height: "68px" }}>
                    <CircularProgressbar
                      value={66}
                      text={`${66}%`}
                      strokeWidth={5}
                      styles={buildStyles({
                        textColor: "white",
                        pathColor: "#546FFF",
                        trailColor: "#1a1e38",
                      })}
                    />{" "}
                  </div> */}
              </Grid>
              {/* <Grid item xs={6} sx={{ marginTop: "5%" }}>
                  <div>
                    <Typography sx={{ fontSize: "20px" }} color="white">
                      100
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }} color="#8E92BC">
                      Task
                    </Typography>
                  </div>
                </Grid> */}
            </Grid>
          </Box>
          {/* </CardActions> */}
        </Card>
      </Grid>
    </Grid>
  );
}
