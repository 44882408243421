// import { CheckBox } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Paper, TableRow } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import OrthoPic from "./../Icons/ortho1.png";
import SpinPic from "./../Icons/spin1.png"
import deleteButton from "./../Icons/deleteButton.svg";
import editButton from "./../Icons/editButton.svg";
import { Check } from '@mui/icons-material';



const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '16px',
    borderColor: '#D4D4D4',
    border: '1px solid #D4D4D4',
    backgroundColor: "#F5F6FA",
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: '80%',

    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'black',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '40ch',
        },
    },
}));



function createData(id, sub_department, members, on_duty, image) {
    return { id, sub_department, members, on_duty, image };
}

const rows = [
    createData("13124325", "Orthopedic Surgery", "30", '6', OrthoPic),
    createData("13124325", "Spine Surgery", "20", '4', SpinPic)


];
const card = (
    <React.Fragment>
        <Grid container spacing={2}>
            <Grid item sm={4}>
                <Typography style={{ fontWeight: '700', fontSize: '24px', padding: '20px' }}>
                    Sub-Departments Details (4)
                </Typography>
            </Grid>
            <Grid item sm={5}>
                <div style={{ padding: '20px' }}>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon style={{ color: '#000000' }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search Sub-Department"
                            inputProps={{ 'aria-label': 'search', color: '#000000' }}
                        />
                    </Search>
                </div>
            </Grid>
            <Grid item sm={3}>
                <div style={{ padding: '20px', display:'flex', justifyContent:'flex-end' }}>
                    <Button variant="contained" disableElevation style={{ color: 'white', textTransform: 'none', backgroundColor: '#246AF1' }}>
                        Create Sub-Department
                    </Button>
                </div>
            </Grid>
        </Grid>
        <div style={{ padding: '20px' }}>
            <TableContainer component={Paper} elevation={0} style={{ borderRadius: '10px' }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead style={{ backgroundColor: '#F1F4F9' }} borderRadius="10px">
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}                        >
                            <TableCell>
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Select
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    ID
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Image
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Sub-Department
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Members
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    On-Duty
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#202224' }}>
                                    Actions
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    <Checkbox />
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#202224'
                                        }}>
                                        {row.id}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <img src={row.image}></img>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#202224'
                                        }}>
                                        {row.sub_department}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#202224'
                                        }}>
                                        {row.members}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#202224'
                                        }}>
                                        {row.on_duty}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Box
                                        sx={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            border: '1px solid',
                                            borderColor: 'divider',
                                            borderRadius: 2,
                                            bgcolor: 'background.paper',
                                            color: 'text.secondary',
                                            '& svg': {
                                                m: 2,
                                            },
                                        }}
                                    >
                                        <img src={editButton}></img>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <img src={deleteButton}></img>
                                    </Box>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </React.Fragment>
);

export default function OutlinedCard() {
    return (
        // <Grid item xs={12} style={{ paddingTop: "20px" }}>
        <div>
            <Paper elevation={0} style={{
                width: "1140px",
                overflowX: "auto",
                backgroundColor: 'white',
                borderRadius: '12px'
            }}>
                {card}
            </Paper>
            {/* <div style={{
                display: 'flex',
                justifyContent: 'space-between', padding: '10px'
            }}>
                <Typography
                    style={{
                        fontSize: '14px'
                    }}>
                    Showing 1-09 of 78
                </Typography>
                <Box
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 2,
                        bgcolor: 'background.paper',
                        color: 'text.secondary',
                        '& svg': {
                            m: 1,
                        },
                    }}
                >
                    <ArrowBackIosIcon style={{ height: '15px', width: '15px' }} />
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <ArrowForwardIosIcon style={{ height: '15px', width: '15px' }} />
                </Box>
            </div> */}
        </div>
        // </Grid>
    );
}
