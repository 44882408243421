import {
    CardActions,
    Grid,
    Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import ContactIcons from "../Icons/contactIcons.svg";
import EditButton from "../Icons/editIcon.svg";
import PageLogo from "../Icons/pageLogo.svg";
import { height } from '@mui/system';

export default function MediaControlCard() {
    const theme = useTheme();

    return (
        <Card sx={{ width: '1140px', height: '150px', borderRadius: '10px' }} elevation={0}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>

                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Grid container>
                        <Grid item sm={2}>
                            <div style={{ display: 'flex', justifyContent: 'center', marginRight: '20px' }}>
                                <img src={PageLogo}
                                    style={{
                                        width: 120, height: 120
                                    }}>

                                </img>
                            </div>
                        </Grid>
                        <Grid item sm={10}>
                            <div style={{ marginLeft: '-20px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography style={{
                                        fontWeight: 700,
                                        fontSize: '24px'
                                    }}>
                                        Riyadh Medical City (Cluster A)
                                    </Typography>
                                    <img src={EditButton}></img>
                                </div>

                                <Typography style={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    marginTop: '-10px'
                                }}>
                                    ID:21987836
                                </Typography>
                                <Box style={{ height: '10px', width: '20px' }}>
                                    <img src={ContactIcons}>
                                    </img>
                                </Box>
                            </div>
                            <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    color: '#A7A7A7',
                                }}>
                                    Created on 13/12/2024 03:00AM
                                </Typography>
                            </CardActions>
                        </Grid>
                    </Grid>

                </CardContent>



            </Box>

        </Card>
    );
}
