import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import BlankImage from "./Icons/imageBlank.svg"
import { InputLabel, TextField } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { fontSize } from '@mui/system';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    maxWidth: 'sm',
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));




export default function CustomizedDialogs() {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>

            <Button variant="contained" disableElevation style={{ textTransform: 'none' }}
                onClick={handleClickOpen}>
                Add new branch
            </Button>
            <Dialog
                fullWidth
                maxWidth="xs"
                PaperProps={{ sx: { borderRadius: "16px" } }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Typography
                        style={{ color: '#202224', fontSize: '18px', fontWeight: '700' }}>
                        Create Branch
                    </Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon style={{ color: '#EE2E31' }} />
                </IconButton>
                <DialogContent>
                    <div style={{ display: 'flex' }}>
                        <img src={BlankImage}></img>
                        <Typography style={{ padding: '20px', color: '#246AF1' }}>
                            Upload Image
                        </Typography>
                    </div>
                    <div>
                        <Typography style={{
                            color: '#202224',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginTop: '20px',
                            marginBottom: '5px'
                        }}>
                            Branch Name*
                        </Typography>
                        <TextField
                            placeholder='Write Branch Name'
                            fullWidth
                            InputProps={{ sx: { borderRadius: 3 } }}
                            borderRadius="5px"
                            size='small'
                            style={{ backgroundColor: '#F1F4F9', marginBottom: '10px' }}
                        >

                        </TextField>
                        <Typography style={{
                            color: '#202224',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginTop: '10px',
                            marginBottom: '5px'
                        }}>
                            Branch Address*
                        </Typography>
                        <TextField
                            placeholder='Choose Location'
                            fullWidth
                            borderRadius="5px"
                            InputProps={{ sx: { borderRadius: 3 } }}

                            size='small'
                            style={{ backgroundColor: '#F1F4F9', marginBottom: '10px' }}
                        >

                        </TextField>
                        <Typography style={{
                            color: '#202224',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginTop: '10px',
                            marginBottom: '5px'
                        }}>
                            Phone Number*
                        </Typography>
                        <PhoneInput
                            country={'sa'}
                            borderRadius="5px"
                            placeholder='Enter Phone number'
                            inputStyle={{
                                backgroundColor: '#F1F4F9', marginBottom: '10px', width: '100%', borderRadius: '5px'
                            }}
                        // onChange={phone => this.setState({ phone })}
                        />
                        {/* <TextField
                            placeholder='Enter Phone number'
                            fullWidth
                            borderRadius="5px"
                            InputProps={{ sx: { borderRadius: 3 } }}

                            size='small'
                            style={{ backgroundColor: '#F1F4F9', marginBottom: '10px' }}
                        >

                        </TextField> */}
                        <Typography style={{
                            color: '#202224',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginTop: '10px',
                            marginBottom: '5px'
                        }}>
                            Number of Seats*
                        </Typography>
                        <TextField
                            placeholder='Enter number of seats'
                            fullWidth
                            borderRadius="5px"
                            InputProps={{ sx: { borderRadius: 3 } }}

                            size='small'
                            style={{ backgroundColor: '#F1F4F9', marginBottom: '10px' }}
                        >

                        </TextField>
                        <Typography style={{
                            color: '#202224',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginTop: '10px',
                            marginBottom: '5px'
                        }}>
                            Branch Admin Email*
                        </Typography>
                        <TextField
                            placeholder='Write Branch Admin Email'
                            fullWidth
                            InputProps={{ sx: { borderRadius: 3 } }}

                            borderRadius="5px"
                            size='small'
                            style={{ backgroundColor: '#F1F4F9', marginBottom: '10px' }}
                        >

                        </TextField>
                        <Typography style={{
                            color: '#202224',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginTop: '10px',
                            marginBottom: '5px'
                        }}>
                            Password*
                        </Typography>
                        <TextField
                            placeholder='Create Password'
                            fullWidth
                            InputProps={{ sx: { borderRadius: 3 } }}

                            borderRadius="5px"
                            size='small'
                            style={{ backgroundColor: '#F1F4F9', marginBottom: '10px' }}
                        >

                        </TextField>
                    </div>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button autoFocus style={{ textTransform: 'none' }} variant='contained' onClick={handleClose}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
