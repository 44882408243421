import {
    Button,
    Typography
} from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";
// import { DataGrid } from '@mui/x-data-grid';

import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
// import useGeneralStyle from "../../assets/css/general";
import { apiClient } from "../../../config/apiClient";
import useCallbackStatus from "../../../hooks/useCallbackStatus";
import useCancelRequest from "../../../hooks/useCancelToken";
import { inviteAdminValidation } from "../../../validations/login-validate";
import Calender from "./Calender";
import SubTable from "./Table";
import Members from "./Members"
import { Box } from "@mui/system";
//   import SuspendAdminModal from "./SuspendAdminModal";
import { useAuth } from "../../../providers/AuthProvider";


const tableHeader = [
    { id: "CreatedAt", align: "left", label: "Select", minWidth: 60 },
    { id: "CreatedAt", align: "left", label: "Branch ID", minWidth: 120 },
    { id: "FirstName", align: "left", label: "Image", minWidth: 60 },
    { id: "LastName", align: "left", label: "Branch Info", minWidth: 60 },
    { id: "RoleName", align: "left", label: "Location", minWidth: 120 },
    { id: "Email", align: "left", label: "Admin Info", minWidth: 120 },
    { id: "Status", align: "left", label: "Created By", minWidth: 70 },
    { id: "Status", align: "left", label: "Created At", minWidth: 60 },
    { id: "Status", align: "left", label: "Actions", minWidth: 60 },


];

// let diffTableHeader = [...tableHeader];
// diffTableHeader.splice(4, 1);

const useStyles = makeStyles((theme2) => ({
    formControl: {
        margin: theme2.spacing(1),
        minWidth: 160,
        color: "white",
        borderBottom: "none",
    },
    cell: {
        fontSize: '14px',
        fontWeight: 400,
        minWidth: 90
    },
    selectEmpty: {
        marginTop: theme2.spacing(2),
    },
    statusBox: {
        width: "150px",
        borderRadius: "5px",
        padding: "5px",
        textAlign: "center",
        fontWeight: "bold",
    },
    icon: {
        fill: "white",
    },
    root: {
        width: "100%",
        overflowX: "auto",
        borderRadius: '12px'
        // marginTop: '25px',
    },
    suspendedStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #fd6868",
        backgroundColor: "#ffc3c3",
        color: "#e64848",
        fontWeight: "bold",
    },
    suspendedStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #fd6868",
        backgroundColor: "#ffc3c3",
        color: "#e64848",
        fontWeight: "bold",
    },
    pendingStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #eab20a",
        backgroundColor: "#ffeeb5",
        color: "#eab20a",
        fontWeight: "bold",
    },
    registeredStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #6ac358",
        backgroundColor: "#c3f4ba",
        color: "#6ac358",
        fontWeight: "bold",
    },
    container: {
        height: "440px",
        width: "100%",
    },
    activeFilter: {
        // backgroundColor: theme2.palette.primary.light,
        borderBottom: "2px solid white ",
        borderRadius: "0px",
        color: "white",
        fontWeight: 600,
    },
    icon: {
        fill: "#FFFFFF",
    },
    carddelModal: {
        width: "500px",
        borderRadius: "12px",
        backgroundColor: "#1b1f3b",
        padding: "1rem",
        position: "relative",

        [theme2.breakpoints.down("sm")]: {
            width: "90vw !important",
        },

        "&:focus-visible": {
            outline: "none",
        },
    },
    profileData: {
        color: "white",
        paddingTop: "2px",
        fontSize: "16px",
        // fontWeight: 600,
    },
    profileHeading: {
        fontSize: "12px",
        // fontWeight: 600,
        color: "#B0B2B6",
        textAlign: "left",
    },

    parentofappointmentcontent: {
        [theme2.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
    },
    bottombox: {
        [theme2.breakpoints.down("xs")]: {
            marginLeft: "0 !important",
            display: "flex",
            justifyContent: "flex-start !important",
            alignItems: "flex-start !important",
        },
    },
    doublerarrow: {
        [theme2.breakpoints.down("xs")]: {
            transform: "rotate(90deg)",
            margin: "1rem !important",
        },
    },

    tabsContainer: {
        backgroundColor: "#fff",
        margin: "20px 20px 0px 20px",
        height: "44px",
        [theme2.breakpoints.down("md")]: {
            width: "95%",
            overflow: "scroll",
        },
        [theme2.breakpoints.down("sm")]: {
            width: "88%",
            overflow: "scroll",
        },
        "&::-webkit-scrollbar": {
            height: "0",
        },
    },
}));



const AdminUsersTable = () => {
    // const globalClasses = useGeneralStyle();
    const classes = useStyles();
    const getAdminListStatus = useCallbackStatus();
    const getUpdateAdminStatus = useCallbackStatus();
    const inviteAdminStatus = useCallbackStatus();

    const apiSource = useCancelRequest();
    const notification = useSnackbar();
    const [search, setSearchKey] = useState("");
    const [adminListData, setAdminListData] = useState([]);
    const [currentStatus, setCurrentStatus] = useState("approved");
    const [filter, setFilter] = useState("approved");
    const [modal, setModal] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [adminComment, setAdminComment] = useState("");
    const [reason, setReason] = useState(false);
    const [rowId, setRowId] = useState(null);
    const [role_id, setRoleId] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const auth = useAuth();
    const {
        values,
        touched,
        errors,
        setValues,
        handleBlur,
        submitForm,
        handleChange,
        resetForm,
    } = useFormik({
        initialValues: {
            email: "",
            first_name: "",
            last_name: "",
            role: null,
            password: null,
        },
        validationSchema: inviteAdminValidation,
        onSubmit: () => {
            // console.table(values);
            inviteAdmin(values);
            // setValues(null);
        },
    });
    let inviteAdmin = async (values) => {
        await inviteAdminStatus.run(
            apiClient("POST", "admin", "manageadmin", {
                body: {
                    role_id: values.role,
                    action: "create",
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    password: values.password,
                },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data },
                    } = result;
                    console.log("=======", data);
                    setModal(!modal);
                    notification.enqueueSnackbar("Admin Invited Successfully", {
                        variant: "success",
                    });
                    resetForm();
                    getAdminList(null, true);
                })
                .catch((error) => {
                    notification.enqueueSnackbar(error.message, {
                        variant: "error",
                    });
                })
        );
    };
    const updateAdminList = async (Id, action) => {
        console.log("id adminlist", Id);

        let apiBody = {};

        // const updateAdminList = async (Id, action) => {
        //   let apiBody = {};
        apiBody = {
            action_user_type: "admin",
            action_user_id: Id,
            action: action,
            comment: adminComment,
        };

        console.log("updateAdminList apibody", apiBody);

        if (currentStatus !== "suspended") {
            delete apiBody.comment;
        }

        try {
            const res = await getUpdateAdminStatus.run(
                apiClient("POST", "admin", "userstatusactions", {
                    body: { ...apiBody },
                    shouldUseDefaultToken: false,
                    cancelToken: apiSource.token,
                    enableLogging: true,
                })
            );

            const {
                content: { data },
            } = res;

            console.log("check updatedData", data);

            getAdminList(null, true);
        } catch (err) {
            if (err && err.code === 403) {
                // logout();
                console.log("error", err);
            } else {
                notification.enqueueSnackbar(err.message, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
            }
        }
    };

    const getAdminList = async (token, refresh = false) => {
        let apiBody = {
            pagination_required: true,
            next_token: token,
        };

        apiBody = {
            search_string: search,
            status: filter,
            search_fields: ["first_name", "last_name", "email"],
        };

        if (apiBody.search_string === "") {
            delete apiBody.search_string;
        }

        try {
            const res = await getAdminListStatus.run(
                apiClient("POST", "admin", "getadminbyfilter", {
                    body: { ...apiBody },
                    shouldUseDefaultToken: false,
                    cancelToken: apiSource.token,
                    enableLogging: true,
                })
            );

            const {
                content: { data, has_more, next_token },
            } = res;
            console.log(data)
            if (refresh) setAdminListData([...data]);
            else {
                setAdminListData([...adminListData, ...data]);
            }
            setHasMore(has_more);
            setTotalPages(next_token);
        } catch (err) {
            if (err && err.code === 403) {
                // logout();

                console.log("error", err);
            } else {
                notification.enqueueSnackbar(err.message, {
                    variant: "err",
                    autoHideDuration: 2000,
                });
            }
        }
    };

    let getAdminRoles = async () => {
        await getAdminListStatus.run(
            apiClient("POST", "common", "manageentities", {
                body: {
                    action: "list",
                    entity_type: "role",
                },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data },
                    } = result;
                    console.log(data);
                    setRoleList(data);
                })
                .catch((error) => {
                    notification.enqueueSnackbar(error.message, {
                        variant: "error",
                    });
                })
        );
    };
    const handleSuspendAction = (rowId) => {
        setRowId(rowId);
        setReason(true);
    };

    useEffect(() => {
        getAdminList(null, true);
    }, [currentStatus, filter]);
    useEffect(() => {
        getAdminRoles();
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getAdminList(null, true);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    const ModalHeader = () => {
        return (
            <>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    style={{ fontSize: "14px" }}
                >
                    <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
                        Invite New Admin Staff
                    </Box>
                    <Button
                        style={{ position: "absolute", right: "20px" }}
                        onClick={() => {
                            setModal(!modal);
                            setValues(null);
                            resetForm();
                        }}
                    >
                        <CloseIcon size="large" style={{ color: "white" }} />
                    </Button>
                    {/* <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
                To: {userObj.email}
              </Box> */}
                </Box>
            </>
        );
    };




    return (
        <>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Typography
                        style={{
                            fontWeight: '400',
                            fontSize: '16px',
                            color: '#A7A7A7'
                        }}>
                        Departments
                    </Typography>
                    <Typography style={{
                        fontWeight: '700',
                        fontSize: '32px'
                    }}>
                        Orthopedic
                    </Typography>
                </div>

            </Box>

            <Grid container spacing={2}>

                <div style={{ marginTop: '20px', width: '' }}>
                    <Calender />

                </div>
                <div style={{ marginTop: '20px' }}>
                    <SubTable />

                </div>
                <div style={{ marginTop: '20px' }}>
                    <Members />

                </div>
                {/* <Grid item sm={12}>
                    <Calender />
                </Grid>
                <Grid item sm={12}>
                    <SubTable />
                </Grid>
                <Grid item sm={12}>
                    <Members />
                </Grid> */}
            </Grid>
        </>
    );
};

export default AdminUsersTable;
