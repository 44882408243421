import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import BackgroundImage from "../Icons/Bg.png"




export default function OutlinedCard({ data }) {
    return (
        <Box >
            <Card style={{
                backgroundColor: '#F5F6FA',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: '400px 400px',
                width: 244, height: 269, borderRadius: '10px'
            }} elevation={0}>
                <CardContent style={{ marginTop: '30px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                        <Avatar
                            alt="Remy Sharp"
                            src={data.image}
                            sx={{ width: 100, height: 100 }}
                        />

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

                        <Typography style={{
                            fontWeight: '700',
                            fontSize: '16px',
                            color: '#202224',
                            fontFamily: 'Ariel, Nunito Sans,sans-serif'
                        }}>
                            {data.name}
                        </Typography>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                        <Typography style={{
                            fontWeight: '600',
                            fontSize: '13px',
                            color: '#848484',
                            fontFamily: 'Nunito Sans,sans-serif'

                        }}>
                            {data.designation}
                        </Typography>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>

                        <Typography style={{
                            fontWeight: '400',
                            fontSize: '14px',
                            color: '#202224',
                            fontFamily: 'Nunito Sans,sans-serif'
                        }}>
                            {data.email}
                        </Typography>

                    </div>
                </CardContent>
            </Card>
        </Box>
    );
}
