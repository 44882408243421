import React, { useState } from 'react'
import { useMemo } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Button, Paper, Typography, Card, CardContent, List, ListItem, ListItemAvatar, Avatar, ListItemText, AvatarGroup } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import FilterIcon from "../Icons/filterIcon.svg";
import { dividerClasses } from '@mui/material/Divider';
import OrthoPic from "./../Icons/ortho.png"
import { Divider, Box } from '@material-ui/core';
import { KeyboardArrowDown, Opacity, RefreshSharp, RestartAlt } from "@mui/icons-material";
import './calender.css'

require('react-big-calendar/lib/css/react-big-calendar.css');




const localizer = momentLocalizer(moment);


export default function MyCalendar() {
    const [selectedEvent, setSelectedEvent] = useState(null);  // State for selected event
    const [cardPosition, setCardPosition] = useState({ x: 0, y: 0 });  // State for card position
    const [isCardVisible, setCardVisible] = useState(false);  // Stat
    const [open, setOpen] = useState(false)


    const dayPropGetter = (date) => {
        const currentMonth = new Date().getMonth();
        const isOutOfRange = date.getMonth() !== currentMonth;

        return {
            style: {
                backgroundColor: isOutOfRange ? 'white' : 'white', // Background color

                backgroundImage: isOutOfRange ? `repeating-linear-gradient(
     45deg,
    #a0c4ff,  /* Blue color for the stripes */
    #a0c4ff 1px,
    #f0f8ff 1px,
    #f0f8ff 10px
  )`: '',
                opacity: '0.5'
                // backgroundSize: isOutOfRange ? '5px 5px' : '', // Adjust the size of the stripes
                // height: isOutOfRange ? '100vh' : '',

            },
        };
    };
    const handleSelectEvent = (event, e) => {
        setSelectedEvent(event);
        setCardPosition({ x: e.clientX, y: e.clientY });  // Set card position based on mouse click position
        setCardVisible(!isCardVisible);  // Show the card when an event is clicked
    };

    const handleCalendarClick = () => {
        setCardVisible(false);  // Hide the card when clicking anywhere else on the calendar
    };
    const formats = {
        weekdayFormat: (date, culture, localizer) =>
            localizer.format(date, 'ddd', culture).toUpperCase(),  // "ddd" for short day names in Moment.js, uppercase
    };
    const [events, setEvents] = useState([
        {
            title: 'Spine On-Call',
            start: new Date(), // Event happening now
            end: new Date(moment().add(1, 'hours').toDate()), // Ends 1 hour later
            color: 1
        },
        {
            title: 'Orthopedic On-Call',
            start: new Date(), // Event happening now
            end: new Date(moment().add(1, 'hours').toDate()), // Ends 1 hour later
            color: 2
        },
        {
            title: 'Spine On-Call',
            start: new Date('2024-10-16T07:00:00'), // Starts on 12th October 2024 at 9 AM
            end: new Date('2024-10-16T07:30:00'), // Ends at 11 AM on the same day
            color: 4
        },
        {
            title: 'Orthopedic On-Call',
            start: new Date('2024-10-16T07:00:00'), // Starts on 12th October 2024 at 9 AM
            end: new Date('2024-10-16T07:30:00'), // Ends at 11 AM on the same day
            color: 4
        },
        {
            title: 'Regional On-duty Card',
            start: new Date('2024-10-03T07:00:00'), // Starts on 12th October 2024 at 9 AM
            end: new Date('2024-10-03T07:30:00'), // Ends at 11 AM on the same day
            color: 1
        },
        {
            title: 'Regional On-duty Card',
            start: new Date('2024-10-12T09:00:00'), // Starts on 12th October 2024 at 9 AM
            end: new Date('2024-10-12T11:00:00'), // Ends at 11 AM on the same day
            color: 3
        },
        {
            title: 'Spin On-call',
            start: new Date('2024-10-15T09:00:00'), // Starts on 12th October 2024 at 9 AM
            end: new Date('2024-10-15T11:00:00'), // Ends at 11 AM on the same day
            color: 4
        },


    ]);
    const eventStyleGetter = (event) => {
        let backgroundColor = 'white';

        if (event.color === 1) {
            backgroundColor = "#FCC981"
        }
        else if (event.color === 2) {
            backgroundColor = "#7551E9";
        }
        else if (event.color === 3) {
            backgroundColor = "#349546"
        }
        else if (event.color === 4) {
            backgroundColor = "#979797"
        }

        return {
            style: {
                backgroundColor,
                color: 'white',
                borderRadius: '5px',
                padding: '0px',
                border: '1px',
            },
        };
    };

    const { defaultDate, views } = useMemo(
        () => ({
            defaultDate: new Date(),
            views: {
                month: true,
            },
        }),
        []
    )
    const timeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };
    const EventComponent = ({ event }) => {

        const startTime = event.start.toLocaleTimeString([], timeFormatOptions); // 07:30 AM
        const endTime = event.end.toLocaleTimeString([], timeFormatOptions);
        return (
            event.color === 1 ? (
                <Box style={{ backgroundColor: '#FCD299', display: 'flex' }}>
                    <div style={{ height: '31px', width: '5px', backgroundColor: '#E98E51' }}>

                    </div>
                    <div>
                        <Typography style={{ fontSize: '10px', fontWeight: 700, color: '#E98E51' }}>
                            {event.title}
                        </Typography>

                        <Typography style={{ fontSize: '10px', fontWeight: 700, color: '#E98E51' }}>
                            {startTime} - {endTime}
                        </Typography>
                    </div>
                </Box>
            ) : event.color === 2 ? (
                (
                    <Box style={{ backgroundColor: '#EDE3FF', display: 'flex' }}>
                        <div style={{ height: '31px', width: '5px', backgroundColor: '#7551E9' }}>

                        </div>
                        <div>
                            <Typography style={{ fontSize: '10px', fontWeight: 700, color: '#7551E9' }}>
                                {event.title}
                            </Typography>

                            <Typography style={{ fontSize: '10px', fontWeight: 700, color: '#7551E9' }}>
                                {startTime} - {endTime}
                            </Typography>
                        </div>
                    </Box>
                )
            ) : event.color === 3 ? (
                (
                    <Box style={{ backgroundColor: '#ADDFB3', display: 'flex' }}>
                        <div style={{ height: '31px', width: '5px', backgroundColor: '#349546' }}>

                        </div>
                        <div>
                            <Typography style={{ fontSize: '10px', fontWeight: 700, color: '#349546' }}>
                                {event.title}
                            </Typography>

                            <Typography style={{ fontSize: '10px', fontWeight: 700, color: '#349546' }}>
                                {startTime} - {endTime}
                            </Typography>
                        </div>
                    </Box>
                )
            ) : (
                (
                    <Box style={{ backgroundColor: '#ededed', display: 'flex' }}>
                        <div style={{ height: '31px', width: '5px', backgroundColor: '#979797' }}>

                        </div>
                        <div>
                            <Typography style={{ fontSize: '10px', fontWeight: 700, color: '#979797' }}>
                                {event.title}
                            </Typography>

                            <Typography style={{ fontSize: '10px', fontWeight: 700, color: '#979797' }}>
                                {startTime} - {endTime}
                            </Typography>
                        </div>
                    </Box>
                )
            )
            // <span>
            //     <strong>{event.title}</strong>
            //     <br />
            //     <span>{event.start.toLocaleTimeString()}</span>
            // </span>

        );
    };
    const CustomToolbar = (toolbar) => {
        const goToBack = () => {
            toolbar.onNavigate("PREV");
        };

        const goToNext = () => {
            toolbar.onNavigate("NEXT");
        };

        const goToCurrent = () => {
            toolbar.onNavigate("TODAY");
        };
        const label = moment(toolbar.date).format("MMMM YYYY");

        return (
            <div >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                    <div>
                        <Typography style={{
                            fontSize: '24px',
                            fontWeight: '700',
                        }}>
                            On-Duty Cards
                        </Typography>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Typography onClick={goToCurrent} style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            marginTop: '5px',
                            color: '#A7A7A7'
                        }}>
                            Today
                        </Typography>
                        <KeyboardArrowLeftIcon style={{ marginTop: '5px' }} onClick={goToBack}>

                        </KeyboardArrowLeftIcon>
                        <span className="toolbar-label">
                            <Typography sx={{
                                fontSize: '24px',
                                fontWeight: '700',
                                fontFamily: 'Nunito Sans, sans-serif'
                            }}>
                                {label}
                            </Typography>
                        </span>
                        <KeyboardArrowRightIcon style={{ marginTop: '5px' }} onClick={goToNext}>

                        </KeyboardArrowRightIcon>
                    </div>
                    <div>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid',
                                borderColor: '#D4D4D4',
                                borderRadius: 10,
                                height:'50px',
                                marginLeft: '10px',
                                backgroundColor: '#F9F9FB',
                                color: 'text.secondary',
                                '& svg': {
                                    m: 1,
                                },
                                [`& .${dividerClasses.root}`]: {
                                    mx: 1,
                                },
                            }}
                        >
                            <div style={{ padding: '20px' }}>
                                <img src={FilterIcon}></img>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div style={{ padding: '20px' }}>
                                <Typography sx={{
                                    fontWeight: 500,
                                    fontSize: '14px'
                                }}>
                                    Filter By
                                </Typography>
                            </div>

                            <Divider orientation="vertical" flexItem />
                            <span
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "Center",
                                    alignItems: "Center",
                                    // marginLeft: "5px"
                                }}
                            >
                                <Typography style={{
                                    padding: '20px',
                                    fontWeight: 500,
                                    fontSize: '14px'
                                }}>
                                    Month
                                </Typography>
                                <KeyboardArrowDown />
                            </span>
                            <Divider orientation="vertical" flexItem />
                            <span
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "Center",
                                    alignItems: "Center",
                                    // marginLeft: "5px"
                                }}
                            >
                                <Typography style={{
                                    padding: '20px', fontWeight: 500,
                                    fontSize: '14px'
                                }}>
                                    Trauma Group
                                </Typography>
                                <KeyboardArrowDown />
                            </span>

                        </Box>
                    </div>
                </div >
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: "10px" }}>
                    <div>
                        <Box style={{ padding: '10px' }}>
                            <Button variant="contained" disableElevation size="large" style={{ textTransform: 'none', backgroundColor: '#F1F4F9', color: 'black' }}>
                                See more
                            </Button>
                        </Box>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <Box style={{ padding: '10px' }}>
                            <Button variant="contained" disableElevation size="large" style={{ textTransform: 'none', backgroundColor: '#F29006', color: 'white' }}>
                                Download Template
                            </Button>
                        </Box>
                        <Box style={{ padding: '10px' }}>
                            <Button variant="contained" disableElevation size="large" style={{ textTransform: 'none', backgroundColor: '#246AF1', color: 'white' }}>
                                Upload CSV
                            </Button>
                        </Box>
                        <Box style={{ padding: '10px' }}>
                            <Button variant="contained" disableElevation size="large" style={{ textTransform: 'none', backgroundColor: '#246AF1', color: 'white' }}>
                                Create On-Duty Card
                            </Button>
                        </Box>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div>
            <Paper sx={{ backgroundColor: 'white', width: '1140px', borderRadius: '10px' }} borderRadius="10px" elevation={0}>
                <div>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        onSelectEvent={handleSelectEvent}
                        style={{ height: 1000, backgroundColor: 'white', padding: '20px' }}
                        views={views}
                        toolbar={true}
                        eventPropGetter={eventStyleGetter} // Apply custom styles
                        dayPropGetter={dayPropGetter} // Use the custom dayPropGetter
                        components={{
                            toolbar: CustomToolbar,
                            event: EventComponent, // Custom event component
                        }}
                        formats={formats}  // Apply custom formats

                    />
                </div>
            </Paper>
            {isCardVisible && selectedEvent && (
                <Paper
                    style={{
                        position: 'absolute',
                        top: cardPosition.y,
                        left: cardPosition.x,
                        zIndex: 1000,  // Ensure the card appears on top
                        width: '300px',
                        height: '240',
                        padding: '10px',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                        backgroundColor: 'white'
                    }}
                >
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <img src={OrthoPic}></img>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={
                                <Typography>
                                    Orthopedic Department
                                </Typography>
                            } secondary={
                                <Typography style={{ color: '#A7A7A7' }}>
                                    327365274
                                </Typography>
                            } />
                        </ListItem>
                    </List>
                    <div>
                        <Typography style={{
                            fontSize: '16px',
                            fontWeight: '700',
                            padding: '5px'
                        }}>
                            {selectedEvent.title}
                        </Typography>
                        <Typography style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#646464',
                            padding: '5px'
                        }}>
                            {selectedEvent.start.toLocaleTimeString([], timeFormatOptions)} - {selectedEvent.end.toLocaleTimeString([], timeFormatOptions)}
                        </Typography>
                        <Typography style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#646464',
                            padding: '5px'
                        }}>
                            On-Duty hrs: 24
                        </Typography>
                        <Typography style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#646464',
                            padding: '5px'
                        }}>
                            Roles: 6
                        </Typography>
                    </div>
                    <Divider style={{ marginTop: '10px', width: '100%' }} />
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <AvatarGroup max={4} style={{ padding: '10px' }}>
                            <Avatar alt="Remy Sharp" src="https://i.pinimg.com/originals/07/33/ba/0733ba760b29378474dea0fdbcb97107.png" />
                            <Avatar alt="Travis Howard" src="https://c.pxhere.com/photos/08/7a/male_portrait_profile_social_media_cv_young_elegant_suit-459413.jpg!d" />
                            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpghttps://writestylesonline.com/wp-content/uploads/2016/08/Follow-These-Steps-for-a-Flawless-Professional-Profile-Picture.jpg" />
                            <Avatar alt="Agnes Walker" src="https://anhede.se/wp-content/uploads/2014/01/130221-2528.jpg" />
                            <Avatar alt="Trevor Henderson" src="https://www.lensmen.ie/wp-content/uploads/2015/02/Profile-Portrait-Photographer-in-Dublin-Ireland..jpg" />
                        </AvatarGroup>
                    </div>
                </Paper>
            )}
        </div>
    )
}

