import { Typography, Grid } from "@mui/material";
import React from "react";
import Card from "../../components/MidOverview/Card";
import Graphs from "../../components/MidOverview/CardAnalytics"

const Overview = () => {
    return (
        // <div className="flex-aifsjcsb flexgap2">
        <>
            {/* <AppBarModule /> */}
            <div>
                <Typography style={{ color: '#202224', fontWeight: '700', fontSize: '32px', marginBottom: '20px' }}>
                    Analytics
                </Typography>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Card />
                    </Grid>
                    <Grid item sm={12}>
                        <Graphs />
                    </Grid>
                </Grid>

            </div>
        </>

    );
};

export default Overview;
